import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const SocialMediaPost = props => {
  const attributes = { ...props }
  delete attributes.url
  delete attributes.thumbnail
  delete attributes.icon
  delete attributes.alert
  delete attributes.title
  delete attributes.className

  return (
    <a
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      className={`text-decoration-none${
        !!props.className ? ` ${props.className}` : ""
      }`}
      {...attributes}
    >
      <div className="card lift shadow border-0">
        <GatsbyImage
          image={getImage(props.thumbnail)}
          layout="fullWidth"
          className="card-img-top"
          alt={props.title}
        />
        <div className="card-body p-6">
          <div className="d-flex flex-row mb-3">
            <img src={props.icon} alt="" className="social-media-icon me-3" />
            {props.alert && (
              <p className="text-danger text-uppercase fw-bold m-0">
                {props.alert}
              </p>
            )}
          </div>
          <h5 className="card-title">
            {props.title.length <= 53
              ? props.title
              : `${props.title.substring(0, 50)}...`}
          </h5>
        </div>
      </div>
    </a>
  )
}

export default SocialMediaPost
