import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Masonry from "react-masonry-css"

import SocialMediaPost from "./SocialMediaPost"

import IconYouTube from "../components/assets/media/youtube.svg"

const SocialMediaFeed = () => {
  const data = useStaticQuery(graphql`
    query {
      allYoutubeVideo(limit: 12, sort: { order: DESC, fields: [publishedAt] }) {
        edges {
          node {
            videoId
            title
            publishedAt
            localThumbnail {
              childImageSharp {
                gatsbyImageData(width: 1080)
              }
            }
          }
        }
      }
    }
  `)

  const youtubePosts = data.allYoutubeVideo.edges.map(({ node }) => ({
    id: node.videoId,
    title: node.title,
    url: `https://www.youtube.com/watch?v=${node.videoId}`,
    publishedAt: new Date(node.publishedAt),
    thumbnail: node.localThumbnail,
    icon: IconYouTube,
  }))

  const allPosts = youtubePosts.sort((a, b) => b.publishedAt - a.publishedAt)

  const items = allPosts
    .filter(i => !!i.thumbnail)
    .map(item => (
      <div key={item.id} className="mb-5">
        <SocialMediaPost
          title={item.title}
          url={item.url}
          icon={item.icon}
          thumbnail={item.thumbnail}
        />
      </div>
    ))

  return (
    <Masonry
      breakpointCols={{
        default: 3,
        992: 3,
        768: 2,
        576: 1,
      }}
      className="d-flex ms-n5 w-100"
      columnClassName="ps-5"
    >
      {items}
    </Masonry>
  )
}

export default SocialMediaFeed
