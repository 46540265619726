import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "../components/SEO"
import SocialMediaFeed from "../components/SocialMediaFeed"
import Navbar from "../components/Navbar"
import SocialMediaPost from "../components/SocialMediaPost"

import useTranslation from "../translations/translate"

import IconYouTube from "../components/assets/media/youtube.svg"

const Explore = ({ pageContext: { pageName } }) => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      allYoutubeVideo(limit: 2, sort: { order: DESC, fields: [publishedAt] }) {
        edges {
          node {
            videoId
            title
            publishedAt
            localThumbnail {
              childImageSharp {
                gatsbyImageData(width: 1080)
              }
            }
          }
        }
      }
    }
  `)

  const yt = data.allYoutubeVideo.edges.map(({ node: item }) => (
    <div key={item.videoId} className="col-12 col-md-6 mb-5 mb-md-0">
      <SocialMediaPost
        title={item.title}
        url={`https://www.youtube.com/watch?v=${item.videoId}`}
        icon={IconYouTube}
        thumbnail={item.localThumbnail}
        alert={t("common.recommended")}
      />
    </div>
  ))

  return (
    <>
      <Navbar />
      <SEO
        title={t("page.explore.title")}
        description={t("page.explore.description")}
        pageName={pageName}
      />

      <section className="bg-light border-top border-bottom py-8">
        <div className="container">
          <div className="row">{yt}</div>
        </div>
      </section>

      <section className="container">
        <p className="h2 mb-8">
          {t("page.explore.weOnInstagramAndYouTube", true)}
        </p>
        <SocialMediaFeed />
      </section>
    </>
  )
}

export default Explore
